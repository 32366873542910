.auth-iframe {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #fff;
    backface-visibility: hidden;

    iframe {
      width: 100%;
      min-height: 100vh;
      align-self: flex-start;
    }
  }
}

body.webview {
  .auth-iframe__overlay {
    padding-top: max(env(safe-area-inset-top), 40px);
  }
}
